import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const ListaDeImoveis = lazy(() => import('../../container/lista-de-imoveis'));

const ListaDeImoveisRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={path} component={ListaDeImoveis} />
    </Switch>
  );
};

export default ListaDeImoveisRoutes;
