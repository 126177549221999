import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { Row, Col, Popover, Modal } from 'antd';
import { SettingDropdwon } from './auth-info-style';
import Heading from '../../heading/heading';

const Settings = () => {
  const [Visible, setVisible] = useState(false);
  const close = () => setVisible(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const content = (
    <SettingDropdwon>
      <div className="setting-dropdwon">
        <Row gutter="10">
          <Col sm={12}>
            <span onClick={showModal} style={{cursor: 'pointer'}}>
              <figure className="setting-dropdwon__single d-flex">
                <img src={require('../../../static/img/icon/007-microphone-1.png')} alt="" />
                <figcaption>
                  <Heading as="h5">Registrar um ticket</Heading>
                  <p>Achou algum problema no sistema ou precisa de ajuda em algo? </p>
                </figcaption>
              </figure>
            </span>
          </Col>
          <Col sm={12}>
            <a href="https://www.youtube.com/watch?v=6O_bVj_91IQ" target="_blank">
              <figure className="setting-dropdwon__single d-flex">
                <img src={require('../../../static/img/icon/017-video-camera.png')} alt="" />
                <figcaption>
                  <Heading as="h5">Primeiros passos no App</Heading>
                  <p>Precisa de uma ajuda para usar ou configurar o app? Assista os nossos tutoriais </p>
                </figcaption>
              </figure>
            </a>
          </Col>
          <Col sm={12}>
            <a href="https://smtximob.com/builder?empresa=yAwI79pUx8zTdo6RoUaK" target="_blank">
              <figure className="setting-dropdwon__single d-flex">
                <img src={require('../../../static/img/icon/010-home.png')} alt="" />
                <figcaption>
                  <Heading as="h5">Landing pages</Heading>
                  <p>Acesse nosso gerenciador de landing pages! </p>
                </figcaption>
              </figure>
            </a>
          </Col>
          <Col sm={12}>
            <a href="https://antigo.smartimob.io/" target="_blank">
            <figure className="setting-dropdwon__single d-flex">
              <img src={require('../../../static/img/icon/014-document.png')} alt="" />
              <figcaption>
                <Heading as="h5">Sistema Web Antigo</Heading>
                <p>Acesse o visual antigo da SmartImob Web! </p>
              </figcaption>
            </figure>
            </a>
          </Col>
          <Col sm={12}>
            <Link to="/admin/email-marketing" onClick={close}>
              <figure className="setting-dropdwon__single d-flex">
                <img src={require('../../../static/img/icon/013-document-1.png')} alt="" />
                <figcaption>
                  <Heading as="h5">Email Marketing</Heading>
                  <p>Gerencie seus públicos e envie emails baseado nas preferencias dos seus clientes!</p>
                </figcaption>
              </figure>
            </Link>
          </Col>
          <Col sm={12}>
            <Link to="/admin/empreendimentos" onClick={close}>
              <figure className="setting-dropdwon__single d-flex">
                <img src={require('../../../static/img/icon/015-color-palette.png')} alt="" />
                <figcaption>
                  <Heading as="h5">Empreendimentos</Heading>
                  <p>Gerencie seus empreendimentos e condomínios!</p>
                </figcaption>
              </figure>
            </Link>
          </Col>
        </Row>
      </div>
    </SettingDropdwon>
  );

  return (
    <div className="settings">
      <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
      <iframe
        src="https://tally.so/embed/mKY5N7?alignLeft=1&hideTitle=1&transparentBackground=1"
        width="100%"
        height="800"
        // @ts-ignore
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        title="Tickets - SMTX">
      </iframe>
      </Modal>
      <Popover placement="bottomRight" onVisibleChange={setVisible} visible={Visible} content={content} trigger="click">
        <Link to="#" className="head-example">
          <FeatherIcon icon="external-link" size={20} />
        </Link>
      </Popover>
    </div>
  );
};

export default Settings;
