import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Propostas = lazy(() => import('../../container/visitas-e-propostas/Propostas'));

const PropostasRoutes = () => {

  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Propostas} />
    </Switch>
  );
};

export default PropostasRoutes;
