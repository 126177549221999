// @ts-nocheck
import { Button, notification } from 'antd';
import React, { lazy, useEffect } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

const Dashboard = lazy(() => import('../../container/dashboard'));

const DashboardRoutes = () => {
  const { path } = useRouteMatch();

  //let history = useHistory()
  //
  //useEffect(() => {
//
  //  const imovel_perdido_cache = localStorage.getItem('imovel_perdido_cache')
  //  if (imovel_perdido_cache) {
  //    try {
  //      const imovel_perdido_cache_data = JSON.parse(imovel_perdido_cache)
  //      if (imovel_perdido_cache_data && Object.keys(imovel_perdido_cache_data).length) {
  //        const click_continuar = () => {
  //          notification.close('imovel_cache')
  //          history.push('/admin/incluir-imovel?cache=true')
  //        }
  //        notification.open({
  //          key: 'imovel_cache',
  //          message: 'Cadastro recuperado!',
  //          description: <div>O sistema foi fechado enquanto você cadastrava um imóvel.<br/>Você quer continuar o cadastro de onde parou?<div style={{marginTop: 4}}><Button onClick={click_continuar} type="primary">Continuar cadastro</Button></div></div>,
  //          onClick: () => {},
  //          onClose: () => localStorage.setItem('imovel_perdido_cache', ''),
  //          duration: 1000000,
  //          icon: <FeatherIcon icon="home"/>
  //        });
  //      }
  //    } catch (error) {
  //      console.log(error, 'imovel_perdido_cache')
  //    }
  //  }
  //}, [])
  return (
    <Switch>
      <Route exact path={path} component={Dashboard} />
      <Route path={`${path}/social`} component={Dashboard} />
    </Switch>
  );
};

export default DashboardRoutes;
