import { combineReducers } from 'redux';
import { readMessageReducer } from './message/reducers';
import { readNotificationReducer } from './notification/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { productReducer, SingleProductReducer } from './product/reducers';
import { userReducer } from './users/reducers';
import Contact from './contact/reducers';
import chartContentReducer from './chartContent/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import { emailReducer, SingleEmailReducer } from './email/reducers';
import Profile from './profile/reducers';
import cartData from './cart/reducers';
import orderReducer from './orders/reducers';
import galleryReducer from './gallery/reducers';
import Calender from './calendar/reducers';

const rootReducers = combineReducers({
  Calender,
  headerSearchData: headerSearchReducer,
  gallery: galleryReducer,
  message: readMessageReducer,
  notification: readNotificationReducer,
  users: userReducer,
  email: emailReducer,
  emailSingle: SingleEmailReducer,
  auth: authReducer,
  ChangeLayoutMode,
  Contact,
  orders: orderReducer,
  cart: cartData,
  Profile,
  chartContent: chartContentReducer, 
  products: productReducer,
  product: SingleProductReducer,
});

export default rootReducers;
