import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/firebase-storage";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCRY1RsUASByrwHaiXSzgLBa_KjcxA7HDk",
  authDomain: "smartimob-dev-test.firebaseapp.com",
  databaseURL: "https://smartimob-dev-test.firebaseio.com",
  projectId: "smartimob-dev-test",
  storageBucket: "smartimob-dev-test.appspot.com",
  messagingSenderId: "1030189605462",
  appId: "1:1030189605462:web:cb42f70fe0f892048051f7",
  measurementId: "G-KC82QWH0ZD"
};

const app = firebase.initializeApp(firebaseConfig);
app.firestore().settings({ experimentalForceLongPolling: true });

export async function uploadImageAsync(uri:string, path:string, id:string, contentType='image/jpeg') {
  try {
    const blob = await new Promise<Blob | Uint8Array | ArrayBuffer>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        resolve(xhr.response);
      };
      xhr.onerror = function(e) {
        console.log(e);
        reject(new TypeError('Network request failed'));
      };
      xhr.responseType = 'blob';
      xhr.open('GET', uri, true);
      xhr.send(null);
    });
  
    const ref = firebase
      .storage()
      .ref(path)
      .child(id);
    const snapshot = await ref.put(blob, contentType !== null ? {contentType: contentType} : undefined);

  
    return await snapshot.ref.getDownloadURL();
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const database = app.firestore();
export const firestore = app.firestore;
export default app;