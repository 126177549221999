import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Message from './message';
import Notification from './notification';
import Settings from './settings';
import Support from './support';
import { Popover } from '../../popup/popup';

import firebase from '../../../firebase'
import SmartCache from '../../../indexeddb'
import Heading from '../../heading/heading';
import AppContext from '../../../context';

const AuthInfo = () => {

  const context = useContext(AppContext)
  const [LoadingSaida, setLoadingSaida] = useState(false)

  const SignOut = async e => {
    e.preventDefault();
    setLoadingSaida(true)
    try {
      await Promise.all([
        SmartCache.imoveis.deleteAll(), 
        SmartCache.clientes.deleteAll(), 
        SmartCache.fotos_upload.deleteAll(), 
        SmartCache.empreendimentos.deleteAll(), 
        SmartCache.visitas.deleteAll()
      ]);
      firebase.auth().signOut();
    } catch (error) {
      console.log(error.message)
    }
    setLoadingSaida(false)
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <div style={{borderRadius: 8, width: 46, height: 46, marginRight: 15, backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${context.user.foto || require('../../../static/img/avatar/user-default.jpg')})`}}></div>
          <figcaption>
            <Heading as="h5">{context.user.nome}</Heading>
            <p>{context.user.cargo}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to={`/admin/equipe/${context.user.db_id}/editar`}>
              <FeatherIcon icon="user" /> Meu Perfil
            </Link>
          </li>
          {
            context.user.permissões.alterar_cadastro && <li>
              <Link to="/admin/configuracoes">
                <FeatherIcon icon="settings" /> Config. Empresa
              </Link>
            </li>
          }
          <li>
            <a href="https://portal.pipefy.com/suporte-smartimob" target="_blank">
              <FeatherIcon icon="bell" /> Ajuda
            </a>
          </li>
        </ul>
        {
          LoadingSaida ? <div className="user-dropdwon__bottomAction">
            <Spin/>
          </div> : <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
            <FeatherIcon icon="log-out" /> Sair
          </Link>
        }
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <Settings/>
      <Notification />
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src={context.user.foto || require('../../../static/img/avatar/user-default.jpg')} />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
