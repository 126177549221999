import React, { useContext, useEffect, useState } from 'react';
import { Input, Row, Col, Breadcrumb } from 'antd';
import { Link, NavLink,useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Div, FixBreadcumbPadding } from './header-search-style';
import { headerSearchAction } from '../../redux/headerSearch/actionCreator';
import { Popover } from '../popup/popup';
import AppContext from '../../context';
import SmartCache from '../../indexeddb';
import { Button } from '../buttons/buttons';
import { UserDropDwon } from '../utilities/auth-info/auth-info-style';


const HeaderSearch = React.memo(() => {
  const { url } = useRouteMatch();
  const [Results, setResults] = useState([])

  const [CodigoKey, setCodigoKey] = useState([])
  const [ClienteNomeKey, setClienteNomeKey] = useState([])
  const context = useContext(AppContext)

  useEffect(() => {
    ;(async () => {
      setCodigoKey(await SmartCache.imoveis.getAllByKeyCursor('codigo'))
      setClienteNomeKey(await SmartCache.clientes.getAllByKeyCursor('nome'))
    })();
  }, [])
  const search = async e => {
    const text = e.target.value;
    if (text) {
      
      const clientes_found = ClienteNomeKey.filter(({nome}) => (nome||'').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
      const codigos_found = CodigoKey.filter(codigo_key => {
        const codigo_parsed = (String(codigo_key.codigo)||'').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        return codigo_parsed.includes((text||'').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
      });
      
      setResults([
        ...clientes_found.map(cliente => ({
          ...cliente,
          tipo: 'cliente'
        })).slice(0, 5),
        ...codigos_found.map(codigo => ({
          ...codigo,
          tipo: 'codigo'
        }))
        .sort((a,b) => (String(b.codigo)||'').toLowerCase() === text ? 1 : -1)
        .slice(0, 5),
        ...context.users_empresa.filter(user => (user.nome||'').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
        .map(user => ({
          ...user,
          tipo: 'funcionario'
        })).slice(0, 4)
      ])
    } else {
      setResults([])
    }
  };

  const content = (
    <div>
      {Results.length ? (
        Results.map((resultado) => {
          const { tipo } = resultado;
          switch (tipo) {
            case 'cliente':
              return (
                <FixBreadcumbPadding>
                  <NavLink to={`/admin/cliente/${resultado.key}/geral`}>
                    <Breadcrumb>
                      <Breadcrumb.Item>
                      Cliente
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                      {resultado.nome}
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </NavLink>
                </FixBreadcumbPadding>
              );
            case 'codigo':
              return (
                <FixBreadcumbPadding>
                  <NavLink to={`/admin/imovel/${resultado.key}`}>
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        Código
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                      {resultado.codigo}
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </NavLink>
                </FixBreadcumbPadding>
              );
            case 'funcionario':
              return (
                <FixBreadcumbPadding>
                  <NavLink to={`/admin/equipe/${resultado.db_id}/editar`}>
                    <Breadcrumb>
                      <Breadcrumb.Item style={{paddingLeft: 0}}>
                        Funcionário
                      </Breadcrumb.Item>
                      <Breadcrumb.Item style={{paddingLeft: 0}}>
                      {resultado.nome}
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </NavLink>
                </FixBreadcumbPadding>
              );
          }
        })
      ) : (
        <NavLink to="#">Nenhum resultado encontrado!</NavLink>
      )}
    </div>
  );
  const atalhos = (
    <UserDropDwon>
      <div className="user-dropdwon" style={{marginTop: 10}}>
        <ul className="user-dropdwon__links">          
          <li>
            <Link to={`/admin/incluir-cliente`}>
              <FeatherIcon icon="user-plus" /> Incluir Cliente
            </Link>
          </li>
          <li>
            <Link to={`/admin/incluir-imovel`}>
              <FeatherIcon icon="plus-square" /> Incluir Imóvel
            </Link>
          </li>
        </ul>
      </div>
    </UserDropDwon>
  )
  // );
  return (
    <>
      <Div className="certain-category-search-wrapper" style={{ width: '100%' }}>
        <Row className="ant-row-middle">
          <Col xl={12} lg={12} xs={24}>
            <div style={{
                display: 'flex',
                alignItems: 'center',                
            }}>
              <Link style={{marginRight: '10px'}} to={`/admin/lista-de-clientes`}>
                <Button type={'white'} outlined icon={<FeatherIcon icon="user" size={12} />}>Clientes</Button>
              </Link>
              <Link style={{marginRight: '10px'}} to={`/admin/lista-de-imoveis`}>
                <Button type={'white'} outlined icon={<FeatherIcon icon="user" size={12} />}>Imóveis</Button>
              </Link>
              <Link style={{marginRight: '10px'}} to={url}>
                <Popover placement={'bottomLeft'} content={atalhos} action="hover">
                  <Button type={'white'} outlined icon={<FeatherIcon icon="chevron-down" size={12} />}>Incluir</Button>
                </Popover>
              </Link> 
            </div>
          </Col>
          <Col xl={12} lg={12} xs={24}>
            <Popover
              placement={'bottomLeft'}
              content={content}
              title="Resultados"
              action="focus"
            >
              <FeatherIcon icon="search" size={16} />
              <Input placeholder="Busca rápida!" onInput={search} />
            </Popover>
          </Col>
        </Row>
      </Div>
    </>
  );
}, () => true);

export default HeaderSearch;
