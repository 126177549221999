import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const DashLocacao = lazy(() => import('../../container/dashboard/DashLocacao'));

const DashLocacaoRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={DashLocacao} />
    </Switch>
  );
};

export default DashLocacaoRoutes;
