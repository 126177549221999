import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { NestedDropdwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';

const Support = () => {
  const content = (
    <NestedDropdwon>
      <div className="support-dropdwon">
        <ul>
          <Heading as="h5">Como usar o sistema?</Heading>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://portal.pipefy.com/suporte-smartimob">Registrar um ticket</a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=6O_bVj_91IQ">Primeiros passos no App</a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=x_nUkHmXhyQ">Landing pages</a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://antigo.smartimob.io/">Sistema Web Antigo</a>
          </li>
        </ul>
      </div>
    </NestedDropdwon>
  );

  return (
    <>
    <div className="support">
      <a target="_blank" className="head-example" style={{display: 'flex', alignItems: 'center', paddingRight: 0, marginRight: 1}}>
        <span>
          Precisa de ajuda?
        </span>
      </a>
    </div>
      <div className="support">
        <Link to="/admin/novidades" className="head-example">
          <FeatherIcon icon="git-commit" size={20} />
        </Link>
      </div>
    </>
  );
};

export default Support;
