import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const HistoricoDeAtividade = lazy(() => import('../../container/historico-atividades'));

/**
 * @deprecated
 */
const HistoricoDeAtividadeRoutes = (props:any) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={path} component={(route_props:any) => <HistoricoDeAtividade {...route_props} {...props}/>} />
    </Switch>
  );
};

export default HistoricoDeAtividadeRoutes;
