import React, { useState, useEffect } from 'react';
import { Badge, message } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { AtbdTopDropdwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';
import AppContext from '../../../context';
import { database } from '../../../firebase';
import SmartCache from '../../../indexeddb';

const NotificationBox = () => {

  const context = React.useContext(AppContext)

  /**
   * @type {{visto: boolean, tipo: 'novo_cliente' | 'lead_ag' | 'novo_imovel', related_id: string, enviado_por: null | string, created_at: Date | {seconds: number}}[]}
   */
  const notificacoes = context.notificacoes;
  const notfs_nao_vistas = notificacoes.filter(notf => !notf.visto).length;

  const content = (
    <AtbdTopDropdwon className="atbd-top-dropdwon">
      <Heading as="h5" style={notificacoes.length === 0 ? {marginBottom: 0} : undefined} className="atbd-top-dropdwon__title">
        <span className="title-text">{notificacoes.length === 0 ? 'Nenhuma notificação' : 'Notificações recentes'}</span>
        {
          notfs_nao_vistas > 0 && <Badge className="badge-success" count={notfs_nao_vistas} />
        }
      </Heading>
        <ul style={{height: 'unset'}} className="atbd-top-dropdwon__nav notification-list">
          {
            notificacoes.map(notf => <Notf key={notf.db_id} notf={notf} uid={context.user.db_id} empresa={context.user.empresa}/>)
          }
        </ul>
    </AtbdTopDropdwon>
  );

  return (
    <div className="notification">
      <Popover placement="bottomLeft" content={content} action="click">
        {
          notfs_nao_vistas > 0 ?
          <Badge dot offset={[-8, -5]}>
            <Link to="#" className="head-example">
              <FeatherIcon icon="bell" size={20} />
            </Link>
          </Badge> :
          <Link to="#" className="head-example">
            <FeatherIcon icon="bell" size={20} />
          </Link>
        }
      </Popover>
    </div>
  );
};

const Notf = (props) => {
  const [Loading, setLoading] = useState(true)

  const [Titulo, setTitulo] = useState(null)

  /**
   * @type {{visto: boolean, tipo: 'novo_cliente' | 'lead_ag' | 'novo_imovel', related_id: string, enviado_por: null | string, created_at: Date | {seconds: number}}
   */
  const notf = props.notf;
  React.useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        switch (notf.tipo) {
          case 'novo_imovel':
            try {
              
              const imovel_cache = await SmartCache.imoveis.getById(notf.related_id)
              if (imovel_cache && (imovel_cache.codigo || imovel_cache.titulo || imovel_cache.db_id)) {
                setTitulo(imovel_cache.codigo ? '#'+imovel_cache.codigo : imovel_cache.titulo || imovel_cache.db_id);
                break;
              } 
            } catch (error) {
              
            }
            const imovel_ref = await database.doc(`empresas/${props.empresa}/imoveis/${notf.related_id}`).get();
            const imovel = imovel_ref.data();
            if (imovel_ref.exists && (imovel.codigo || imovel.titulo || imovel.db_id)) {
              setTitulo(imovel.codigo ? '#'+imovel.codigo : imovel.titulo || imovel.db_id);
            } else {
              setTitulo('Imóvel excluído')
            }
            break;
          case 'lead_ag':
          case 'recadastro_cliente':
          case 'novo_cliente':
            try {
              const cliente_cache = await SmartCache.clientes.getById(notf.related_id)
              if (cliente_cache && (cliente_cache.nome || cliente_cache.email)) {
                setTitulo(cliente_cache.nome || cliente_cache.email);
                break;
              }
              
            } catch (error) {
              
            }
            const cliente_ref = await database.doc(`empresas/${props.empresa}/clientes/${notf.related_id}`).get();
            const cliente = cliente_ref.data();
            
            if (cliente)
              setTitulo(cliente.nome || cliente.email);
            break;
        }
      } catch (error2) {
        console.log(error2)
      }
      setLoading(false)
    })();
  }, []);

  let link = '#';
  let icon;
  let pre_texto;
  let pos_texto;
  switch (notf.tipo) {
    case 'novo_imovel':
      pre_texto = 'O imóvel '
      pos_texto = 'foi registrado!'
      icon = 'home'
      link = `/admin/imovel/${notf.related_id}`
      break;
    case 'lead_ag':
      pre_texto = 'O lead '
      pos_texto = 'foi passado para você!'
      icon = 'user'
      link = `/admin/cliente/${notf.related_id}/editar`
      break;
    case 'novo_cliente':
      pre_texto = 'O cliente '
      pos_texto = `foi cadastrado${notf.enviado_por === null ? '!' : ' por um corretor.'}`
      icon = 'user-plus'
      link = `/admin/cliente/${notf.related_id}/editar`
      break;
    case 'recadastro_cliente':
      pre_texto = 'O cliente '
      pos_texto = `se cadastrou novamente no site.`;
      icon = 'user-plus'
      link = `/admin/cliente/${notf.related_id}/editar`
      break;
  }
  const viz = () => !notf.visto ? database.doc(`users/${props.uid}/notificacoes/${notf.db_id}`).update({visto: true}) : undefined;

  return <li onMouseEnter={viz}>
    <Link to={link}>
      <div className="atbd-top-dropdwon__content notifications">
        <div className={`notification-icon bg-${notf.tipo === 'novo_cliente' ? 'primary' : 'secondary'}`}>
          <FeatherIcon icon={Loading ? "loading": icon} />
        </div>
        <div className="notification-content d-flex">
          <div className="notification-text">
            <Heading as="h5">
              {
                Loading ? <span>Carregando...</span> : <>{pre_texto}<span>{Titulo}</span> {pos_texto}</>
              }
            </Heading>
            <p>{new Date(notf.created_at.seconds * 1000).toLocaleString()}</p>
          </div>
          <div className="notification-status">
            {
              !notf.visto && <Badge dot />
            }
          </div>
        </div>
      </div>
    </Link>
  </li>
}

export default NotificationBox;
