import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Empreendimento = lazy(() => import('../../container/profile/empreendimento/Index'));

const EmpreendimentosRoutes = () => {
  const { path } = useRouteMatch();
  
  return (
    <Switch>
        <Route path={`${path}/:id_empreendimento`} component={Empreendimento} />
    </Switch>
  );
};

export default EmpreendimentosRoutes;
