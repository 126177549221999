import React from 'react';
import { ErrorWrapper } from './style';
import { NavLink } from 'react-router-dom';
import { Main } from '../styled';
import Heading from '../../components/heading/heading';
import { Button } from '../../components/buttons/buttons';

/**
 * @param {any} styleWrapper 
 * @param {any} style
 */
const NotFound = ({title, btnText, btnLink = undefined, styleWrapper = undefined, style = undefined, clickBtn = undefined}) => {
  return (
    <Main style={styleWrapper}>
      <ErrorWrapper style={style}>
        <img src={require(`../../static/img/pages/404.svg`)} alt="404" />
        <Heading className="error-text" as="h3">
          404
        </Heading>
        <p>{title}</p>
        {
          btnLink ? 
          <NavLink to={btnLink}>
            <Button size="default" type="primary" to={btnLink}>
              {btnText}
            </Button>
          </NavLink> :
          <Button size="default" type="primary" onClick={clickBtn}>
            {btnText}
          </Button>
        }
      </ErrorWrapper>
    </Main>
  );
};

export default NotFound;
