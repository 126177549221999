import { CustomIDB } from "./indexeddb";
type store_name = ("imoveis" | "empreendimentos" | "clientes" | "visitas")
const stores:store_name[] = ['imoveis','empreendimentos' ,'clientes' ,'visitas']

// @todo: testes de streamAllByIndex e streamAll

const index_testes:{ [store in store_name]: [string, any] } = {
    imoveis: ['ativo', 1],
    empreendimentos: ['ativo', 1],
    clientes: ['ativo', 1],
    visitas: ['dispositivo', 'desktop']
}
const fields: { [store in store_name]: string[] } = {
    imoveis: [ 'ativo', 'codigo', 'rua' ],
    empreendimentos: [ 'ativo', 'edited_at' ],
    clientes: [ 'ativo', 'nome' ],
    visitas: [ 'dispositivo', 'time_ultima_visita' ],
}
const tempos_store: { [store in store_name]: number } = {
    imoveis: 0,
    empreendimentos: 0,
    clientes: 0,
    visitas: 0,
}
const tempos = new Map()
let tempo_store:store_name = stores[0]

const check_db_id = (e) => String(e.db_id||'').includes('1')
/**
 * Execute essa função no navegador para testar a memoização. lembre de testar em 2 empresas diferentes para ter um resultado mais seguro.
 * 
 * Esses testes tem como função principal certificar que as informações memoizadas são SEMPRE IGUAIS as não memoizadas, garantindo que não tenha nenhum comportamento estranho no sistema.
 * Esses testes tem como função secundaria exibir como a memoização pode beneficiar a performance.
 */
export async function test_idb(options: { neverClear: boolean, onlyM?: boolean } = { neverClear: false, onlyM: false }) {
    const { neverClear, onlyM } = options;
    const pre_test = performance.now();
    for await (const store of stores) {
        tempo_store = store
        const pre_test_store = performance.now();
        try {
            const Memo = new CustomIDB(store)
            const NoMemo = new CustomIDB(store, onlyM)
            await Promise.all([NoMemo.init(), Memo.init()])
            await compare_arrays(Memo.getAll(), Memo.getAll(), '1. mm getAll')
            await compare_arrays(Memo.getAll(), NoMemo.getAll(), '2. mn getAll')

            await compare_arrays(Memo.getAllFiltered(() => true), Memo.getAllFiltered(() => true), '3. mm getAllFiltered')
            if (!neverClear) {
                Memo.clearCaches()
            }
            await compare_arrays(Memo.getAllFiltered(() => true), Memo.getAllFiltered(() => true), '4. mm getAllFiltered clear')
            if (!neverClear) {
                Memo.clearCaches()
            }
            
            // testar função nomeada
            await compare_arrays(Memo.getAllFiltered(check_db_id), NoMemo.getAllFiltered(check_db_id), '4.1. nm getAllFiltered clear')
            // testar função anonima
            await compare_arrays(Memo.getAllFiltered((e) => String(e.db_id||'').includes('1')), NoMemo.getAllFiltered(check_db_id), '4.2. nm getAllFiltered')
            
            const [index_name, index_val] = index_testes[store]
            
            await compare_arrays(NoMemo.getAllByIndex(index_name, index_val), Memo.getAllByIndex(index_name, index_val), '5. nm getAllByIndex')
            
            
            if (!neverClear) {
                Memo.clearCaches()
            }
            await compare_arrays(NoMemo.getAllByIndex(index_name, index_val), Memo.getAllByIndex(index_name, index_val), '6. nm getAllByIndex clear')
            await compare_arrays(Memo.getAllByKeyCursor(index_name), NoMemo.getAllByKeyCursor(index_name), '7. mn getAllByKeyCursor')
            
            await compare_arrays(Memo.getAllByKeyCursor(index_name), Memo.getAllByKeyCursor(index_name), '8. mm getAllByKeyCursor')
            
            await compare_arrays(NoMemo.getAllByKeyCursor(index_name), Memo.getAllByKeyCursor(index_name), '9. nm getAllByKeyCursor')
            await compare_arrays(NoMemo.getAllByIndexFilter(index_name, index_val, check_db_id), Memo.getAllByIndexFilter(index_name, index_val, (e) => String(e.db_id||'').includes('1')), '10. nm getAllByIndexFilter')
            
            if (!neverClear) {
                Memo.clearCaches()
            }
            await compare_arrays(Memo.getAllByIndexFilter(index_name, index_val, (e) => String(e.db_id||'').includes('1')), Memo.getAllByIndexFilter(index_name, index_val, (e) => String(e.db_id||'').includes('1')), '11. mm getAllByIndexFilter clear')
            
            await compare_arrays(NoMemo.getAllByIndexFilter(index_name, index_val, (e) => String(e.db_id||'').includes('1')), Memo.getAllByIndexFilter(index_name, index_val, (e) => String(e.db_id||'').includes('1')), '12. nm getAllByIndexFilter')
            
            if (!neverClear) {
                Memo.clearCaches()
            }

            await compare_arrays(NoMemo.getAllByIndexFilter(index_name, index_val, (e) => String(e.db_id||'').includes('1')), Memo.getAllByIndexFilter(index_name, index_val, (e) => String(e.db_id||'').includes('1')), '13. nm getAllByIndexFilter clear')
            await compare_arrays(NoMemo.getFieldsByCursor(...fields[store]), Memo.getFieldsByCursor(...fields[store]), '14. nm getFieldsByCursor')
            
            await compare_arrays(Memo.getFieldsByCursor(...fields[store]), Memo.getFieldsByCursor(...fields[store]), '15. mm getFieldsByCursor')
            
            if (!neverClear) {
                Memo.clearCaches()
            }
            await compare_arrays(Memo.getFieldsByCursor(...fields[store]), Memo.getFieldsByCursor(...fields[store]), '16. mm getFieldsByCursor clear')
            
            if (!neverClear) {
                Memo.clearCaches()
            }
            await compare_arrays(NoMemo.getFieldsByCursor(...fields[store]), Memo.getFieldsByCursor(...fields[store]), '17. nm getFieldsByCursor clear')
            
        } catch (error) {
            console.log(error)
            return false
        }
        const pos_test_store = performance.now();
        tempos_store[store] = pos_test_store - pre_test_store
        console.log(`Testes na store: ${store} finalizados. Tempo: ${Math.round(tempos_store[store])}ms.`)
    }
    const pos_test = performance.now();
    console.log(`Todos os testes executados com sucesso! Tempo: ${Math.round(pos_test - pre_test)}ms `, { tempos, options, tempos_store })
    if (!neverClear) {
        console.log(`Considere executar essa função com o parametro { neverClear: true }. Esse parametro permite o cache entre testes, e reflete de maneira melhor o que pode ocorrer no sistema e demostra o tempo que pode ser economizado ao utilizar memoização.`)
    }
    if (!onlyM) {
        console.log(`Considere executar essa função com o parametro { onlyM: true }. Esse parametro executa apenas os testes com o use_memo = true, refletindo de maneira melhor o ganho em performance ao utilizar memoização.`)
    }
    return true
}

const compare_arrays = async (x:Promise<any[]>, y:Promise<any[]>, test_name: string) => {
    const pre_call_x = performance.now()
    const x_done = await x
    const pos_call_x = performance.now()
    const pre_call_y = performance.now()
    const y_done = await y
    const pos_call_y = performance.now()
    const tempo_info = {
        x: pos_call_x - pre_call_x, y: pos_call_y - pre_call_y
    }
    tempos.set(tempo_store+' '+test_name, tempo_info)
    const equals = x_done.every((item, i) => object_equals(y_done[i], x_done[i]))
    const err_i:number[] = []
    const noted_i:(string|boolean)[] = []
    if (!equals) throw {
        test_name, 
        x_done, 
        y_done, 
        tempo_info,
        noted_i,
        err_i,
        diff: x_done.filter((item, i) => {
            const pass = object_equals(y_done[i], x_done[i])
            if (!pass) {
                err_i.push(i)
                noted_i.push(noted_object_equals(y_done[i], x_done[i]))
            }
            return !pass
        }).map((item, i) => ({ x: x_done[i], y: y_done[i] }) )
    }
    return true
}

function noted_object_equals( x, y ) {
    if ( x === y ) return true;
      // if both x and y are null or undefined and exactly the same
  
    if ( ! ( x instanceof Object ) || ! ( y instanceof Object ) ) return ' ! ( x instanceof Object ) || ! ( y instanceof Object ) ';
      // if they are not strictly equal, they both need to be Objects
  
    if ( x.constructor !== y.constructor ) return ' x.constructor !== y.constructor ';
      // they must have the exact same prototype chain, the closest we can do is
      // test there constructor.
  
    for ( var p in x ) {
      if ( ! x.hasOwnProperty( p ) ) continue;
        // other properties were tested using x.constructor === y.constructor
  
      if ( ! y.hasOwnProperty( p ) ) return ` ! y.hasOwnProperty( ${p} ) `;
        // allows to compare x[ p ] and y[ p ] when set to undefined
  
      if ( x[ p ] === y[ p ] ) continue;
        // if they have the same strict value or identity then they are equal
        
      if ( isNaN(x[ p ]) && isNaN(y[ p ]) ) continue;
      // uau como eu amooooooooo javascript...................... https://stackoverflow.com/questions/6976721/is-nan-equal-to-nan
  
      if ( typeof( x[ p ] ) !== "object" ) return ` typeof( x[ ${p} ] ) !== "object" {{ ${typeof( x[ p ] )}->${ x[ p ] } ${typeof( y[ p ] )}->${ y[ p ] } }}`;
        // Numbers, Strings, Functions, Booleans must be strictly equal
  
      if ( ! object_equals( x[ p ],  y[ p ] ) ) return ` ! object_equals( x[ ${p} ],  y[ ${p} ] ) `;
        // Objects and Arrays must be tested recursively
    }
  
    for ( p in y )
      if ( y.hasOwnProperty( p ) && ! x.hasOwnProperty( p ) )
        return ` y.hasOwnProperty( ${p} ) && ! x.hasOwnProperty( ${p} ) `;
          // allows x[ p ] to be set to undefined
  
    return true;
}

function object_equals( x, y ) {
    if ( x === y ) return true;
      // if both x and y are null or undefined and exactly the same
  
    if ( ! ( x instanceof Object ) || ! ( y instanceof Object ) ) return false;
      // if they are not strictly equal, they both need to be Objects
  
    if ( x.constructor !== y.constructor ) return false;
      // they must have the exact same prototype chain, the closest we can do is
      // test there constructor.
  
    for ( var p in x ) {
      if ( ! x.hasOwnProperty( p ) ) continue;
        // other properties were tested using x.constructor === y.constructor
  
      if ( ! y.hasOwnProperty( p ) ) return false;
        // allows to compare x[ p ] and y[ p ] when set to undefined
  
      if ( x[ p ] === y[ p ] ) continue;
        // if they have the same strict value or identity then they are equal
  
      if ( isNaN(x[ p ]) && isNaN(y[ p ]) ) continue;
        // uau como eu amooooooooo javascript...................... https://stackoverflow.com/questions/6976721/is-nan-equal-to-nan
      if ( typeof( x[ p ] ) !== "object" ) return false;
        // Numbers, Strings, Functions, Booleans must be strictly equal
  
      if ( ! object_equals( x[ p ],  y[ p ] ) ) return false;
        // Objects and Arrays must be tested recursively
    }
  
    for ( p in y )
      if ( y.hasOwnProperty( p ) && ! x.hasOwnProperty( p ) )
        return false;
          // allows x[ p ] to be set to undefined
  
    return true;
}
  