import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Pipeline = lazy(() => import('../../container/pipeline-clientes'));

const PipelineRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Pipeline} />
    </Switch>
  );
};

export default PipelineRoutes;
