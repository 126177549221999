import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Email = lazy(() => import('../../container/email/Email'));

const EquipeRoutes = () => {
    const { path } = useRouteMatch();
    
    return (
        <Switch>
            <Route path={path} component={Email} />
        </Switch>
    );
};

export default EquipeRoutes;
