import { Moment, isMoment } from 'moment';
import { Imóvel } from 'smart-imob-types';

/**
 * @param {number} start_time
 * @param {number} end_time
 * @returns Segundos da visita
 */
export const TempoVisita = (start_time: number, end_time: number) => (end_time - start_time) / 1000;
/**
 * @param {number} start_time
 * @param {number} end_time
 */
export const MesmoDia = (start_time: number, end_time: number) => {
  const [start_date, end_date] = [new Date(start_time), new Date(end_time)];
  return (
    start_date.getDate() === end_date.getDate() &&
    start_date.getMonth() === end_date.getMonth() &&
    start_date.getFullYear() === end_date.getFullYear()
  );
};
/**
 * @param {number} start_time
 * @param {number} end_time
 */
export const MesmoMes = (start_time: number, end_time: number) => {
  const [start_date, end_date] = [new Date(start_time), new Date(end_time)];
  return start_date.getMonth() === end_date.getMonth() && start_date.getFullYear() === end_date.getFullYear();
};

export function differenceInDays(date1: Date, date2: Date) {
  let Difference_In_Time = date2.getTime() - date1.getTime();
  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return Difference_In_Days;
}

/**
 * @see https://stackoverflow.com/questions/31422033/check-if-selected-dates-are-from-the-same-week-javascript
 * @param {number} prev_time
 * @param {number} future_time
 */
export const _UNSAFE_MesmaSemana = (time1: number, time2: number) => {
  let start_date;
  let end_date;
  if (time1 > time2) {
    start_date = new Date(time2);
    end_date = new Date(time1);
  } else {
    start_date = new Date(time1);
    end_date = new Date(time2);
  }
  //@ts-ignore
  if (end_date - start_date > 518400000 || start_date.getDay() > end_date.getDay()) {
    return false;
  }
  return true;
};
//https://github.com/date-fns/date-fns/blob/38f727ba038a62d4c514033e5dde815c077ff5f9/src/startOfWeek/index.js#L36
function startOfWeek(date: Date) {
  var day = date.getDay();
  var diff = (day < 0 ? 7 : 0) + day - 0;

  date.setDate(date.getDate() - diff);
  date.setHours(0, 0, 0, 0);
  return date;
}

//https://github.com/date-fns/date-fns/blob/master/src/isSameWeek/index.js
export function MesmaSemana(time1: number, time2: number) {
  const dateLeftStartOfWeek = startOfWeek(new Date(time1));
  const dateRightStartOfWeek = startOfWeek(new Date(time2));

  return dateLeftStartOfWeek.getTime() === dateRightStartOfWeek.getTime();
}

export function getPercentage(x: number, y: number) {
  let max = x > y ? x : y;
  let min = x > y ? y : x;
  return ((max - min) / max) * 100;
}

export function getPercentageAlt(x: number, y: number) {
  let max = x > y ? x : y;
  let min = x > y ? y : x;
  return min / (max / 100);
}

export const WhatIsXPercentOfY = (x: number, y: number) => (x / 100) * y;
export const XisWhatPercentOfY = (x: number, y: number) => (x / y) * 100;
export const PercentageIncreaseFromXtoY = (x: number, y: number) => {
  const res = ((x - y) / y) * 100;
  return isNaN(res) ? 0 : res;
};

export type UnsafeDate = Date | { seconds: number; toMillis?: () => number } | number | string | Moment;

export const DateFromUnsafe = (unsafeDate: UnsafeDate): Date => {
  if (unsafeDate instanceof Date) return unsafeDate;
  if (typeof unsafeDate === 'number' || typeof unsafeDate === 'string') return new Date(unsafeDate);
  if (isMoment(unsafeDate)) return unsafeDate.toDate();
  return new Date(unsafeDate.toMillis ? unsafeDate.toMillis() : unsafeDate.seconds * 1000);
};
const formatter = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
const formatterDolar = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
const formatterEuro = Intl.NumberFormat('en-IN', { style: 'currency', currency: 'EUR' });
export const toBRL = (value: number | bigint, type?: string) => {
  if (!type) return formatter.format(value);
  if (type === 'Real') return formatter.format(value);
  if (type === 'Dolar') return formatterDolar.format(value);
  if (type === 'Euro') return formatterEuro.format(value);
};
export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const getFotoDestaque = (imovel: Imóvel, resized?: boolean): undefined | string => {
  // Valida se o imóvel possui fotos
  const fotos = imovel?.fotos || [];
  if (fotos.length === 0) return undefined;

  
  const foto =
    (imovel?.foto_destaque_index !== undefined && fotos[imovel.foto_destaque_index]) ||
    fotos.find(foto => foto?.destaque) ||
    fotos[0];

  if (!foto) return undefined;

  
  return resized ? foto.resized || foto?.source?.uri : foto?.source?.uri;
};

export const generatePhoneVariations = (phone: string, DDD?: string): string[] => {
  // Remove todos os caracteres não numéricos do telefone
  let cleanedPhone = phone.replace(/\D/g, '');
  const variations = new Set<string>();

  // Extrair o DDD do número de telefone, se necessário
  if (!DDD && cleanedPhone.length > 10) {
    DDD = cleanedPhone.slice(0, 2);
    cleanedPhone = cleanedPhone.slice(2);
  }

  // Função para adicionar variações base
  const addBaseVariations = (number: string) => {
    if (number.length === 8 || number.length === 9) {
      const baseNumber = number.length === 9 ? number.slice(1) : number;
      variations.add(baseNumber); // 12345678
      variations.add(`9${baseNumber}`); // 912345678
    } else if (number.length === 10 || number.length === 11) {
      const baseNumber = number.length === 11 ? number.slice(2) : number.slice(1);
      variations.add(baseNumber); // 12345678
      variations.add(`9${baseNumber}`); // 912345678
    }
  };

  // Adiciona variações sem DDD
  addBaseVariations(cleanedPhone);

  // Adiciona variações com DDD
  if (DDD) {
    variations.add(`${DDD}${cleanedPhone}`);
    if (cleanedPhone.length === 8) {
      variations.add(`${DDD}9${cleanedPhone}`);
    } else if (cleanedPhone.length === 9) {
      variations.add(`${DDD}${cleanedPhone}`);
      variations.add(`${DDD}${cleanedPhone.slice(1)}`);
    } else if (cleanedPhone.length === 10) {
      variations.add(`${DDD}${cleanedPhone}`);
      variations.add(`${DDD}9${cleanedPhone.slice(2)}`);
    } else if (cleanedPhone.length === 11) {
      variations.add(`${DDD}${cleanedPhone}`);
      variations.add(`${DDD}${cleanedPhone.slice(2)}`);
      variations.add(`${DDD}${cleanedPhone.slice(0, 2)}${cleanedPhone.slice(3)}`);
    }
  }

  // Adicionar formatações com hífen e espaço
  const addFormattedVariations = (number: string) => {
    if (number.length >= 8) {
      const withHyphen = `${number.slice(0, number.length - 4)}-${number.slice(number.length - 4)}`;
      const withSpace = `${number.slice(0, number.length - 4)} ${number.slice(number.length - 4)}`;
      variations.add(withHyphen);
      variations.add(withSpace);

      // Adiciona variações com DDD e espaço se o DDD estiver presente e não estiver na string
      if (DDD && !number.startsWith(DDD)) {
        variations.add(`${DDD} ${withHyphen}`);
        variations.add(`${DDD} ${withSpace}`);
      }
    }
  };


  // Adiciona variações formatadas
  Array.from(variations).forEach(variation => {
    addFormattedVariations(variation);
  });

  // Adiciona o telefone original e sem o DDD se necessário
  variations.add(phone);
  if (DDD) {
    const phoneWithoutDDD = cleanedPhone;
    addBaseVariations(phoneWithoutDDD);
  }

  return Array.from(variations);
};

export default {
  TempoVisita,
  MesmoDia,
  MesmoMes,
  toBRL,
  MesmaSemana,
  getPercentage,
  DateFromUnsafe,
  generatePhoneVariations,
};
