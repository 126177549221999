import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Portais = lazy(() => import('../../container/pages/portais'));

const PortaisRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Portais} />
    </Switch>
  );
};

export default PortaisRoutes;
