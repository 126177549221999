import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Calendario = lazy(() => import('../../container/agenda/Calendario'));

const CalendarioRoutes = (props:any) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={path}>
        <Calendario {...props}/> 
      </Route>
    </Switch>
  );
};

export default CalendarioRoutes;
