import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Equipe = lazy(() => import('../../container/pages/Equipe'));
const Usuario = lazy(() => import('../../container/profile/usuario/Index'));

const EquipeRoutes = () => {
  const { path } = useRouteMatch();
  
  return (
    <Switch>
      <Route exact path={path} component={Equipe} />
      <Route path={`${path}/:id_user`} component={Usuario} />
    </Switch>
  );
};

export default EquipeRoutes;
