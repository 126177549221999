// @ts-nocheck
import { Button, notification } from 'antd';
import React, { lazy, useEffect } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

const Dashboard = lazy(() => import('../../container/dashboard-temporada'));

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Dashboard} />
      <Route path={`${path}/social`} component={Dashboard} />
    </Switch>
  );
};

export default DashboardRoutes;
