import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Imovel = lazy(() => import('../../container/profile/imovel/Index'));

const ImovelRoutes = () => {
    const { path } = useRouteMatch();
    
    return (
        <Switch>
            <Route path={`${path}/:id_imovel`} component={Imovel} />
        </Switch>
    );
};

export default ImovelRoutes;
