import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Suporte = lazy(() => import('../../container/suporte/index'));

const SuporteRoutes = (props: any) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} component={(route_props: any) => <Suporte {...route_props} {...props} />} />
    </Switch>
  );
};

export default SuporteRoutes;
