import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Gerenciar = lazy(() => import('../../container/gerenciar-imovel'));
// import Gerenciar from '../../container/gerenciar-imovel';

const IncluirImovelRoutes = (props:any) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={(route_props:any) => <Gerenciar {...route_props} {...props}/> } />
    </Switch>
  );
};

export default IncluirImovelRoutes;
