import Worker from 'worker-loader!./Worker';

// Worker principal, contem o SDK de recomendação
const mainWorker = new Worker();

const subWorker = new Worker();

type WorkerActionType = {
    /**
     * Nome da ação
     */
    fn: 'sync-clientes' | 'sync-empreendimentos' | 'sync-imoveis' | 'sync-visitas' | 'recommender-update' | 'recommender-by-imovel-id' | 'recommender-para-visitante',
    input?: {
        empresa_id?: string,
        user_id?: string,
        sab?: SharedArrayBuffer
        categoria?: 'locacao' | 'venda'
        only_from_corretor_e_proprietarios?: string
    },
    config?: {
        debugPerformance?: boolean
    },
}

/**
 * Envia uma solicitação de execução de função para o web worker
 * @param action Ação a ser executada no web worker
 * @param useSub Caso true, utiliza um worker secundário, as vezes é bom dividir a carga para melhora a performance
 */
export const WorkerAction = <Return = boolean>(action:WorkerActionType, useSub?:boolean) => new Promise<Return>((resolve, reject) => {

    const workerToUse = useSub ? subWorker : mainWorker;

    // ID para ajudar a debuggar
    const action_id = Math.random();
    // Medidor de performance
    const p_before = performance.now();
    console.log('NEW WA', action_id, action.fn, action)

    function WorkerActionCallback(event: MessageEvent) {
        const { output, from } = event.data
        console.log(event.data)
        if (from === action_id) {
            // Quando a chamada for finalizada a promise vai ser resolvida
            resolve(output)
            if (action.config?.debugPerformance) {
                console.log({ action_id, doneInMs: performance.now() - p_before, action, output });
            }
            workerToUse.removeEventListener('message', WorkerActionCallback)
        }
    }

    workerToUse.addEventListener('message', WorkerActionCallback)
    workerToUse.postMessage({...action, from: action_id})
});

export default mainWorker;