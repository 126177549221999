import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Unidades = lazy(() => import('../../container/unidades/index'));

const UnidadesRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Unidades} />
    </Switch>
  );
};

export default UnidadesRoutes;
