import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Tarefas = lazy(() => import('../../container/tarefas'));

const TarefasRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
        <Route exact path={path} component={Tarefas} />
    </Switch>
  );
};

export default TarefasRoutes;
