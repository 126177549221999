import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Equipes = lazy(() => import('../../container/equipes/index'));

const EquipesRoutes = () => {
  const { path } = useRouteMatch();
  
  return (
    <Switch>
      <Route exact path={path} component={Equipes} />
    </Switch>
  );
};

export default EquipesRoutes;
