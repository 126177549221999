import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import store from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import firebase from './firebase';

const { theme } = config;

const ProviderConfig = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [waitingForAuthCheck, setwaitingForAuthCheck] = useState(true)

  const [path, setPath] = useState(window.location.pathname);
  //from é usado para saber qual guia foi acessada pelo usuario e redirecionar até lá se possivel
  const [from, setFrom] = useState(window.location.pathname);
  //parametros app
  const [param, setParam] = useState(window.location.search || '');
  
  /**
   * Sistema de login, quando o firebase detecta uma mudança ou inicialização
   * no state do usuario ele muda o state da pagina pra refletir a mudança 
   */
  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      if (!isLoggedIn) {
        setLoggedIn(true)
      }
    } else {
      setLoggedIn(false);
    }
    setwaitingForAuthCheck(false)
  });

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => {(unmounted = true)};
  }, [setPath]);

  // Cors().use((req, res, next) => {
  //   res.header("Access-Control-Allow-Origin", "*");
  //   res.header("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
  //   next();
  // })
  
  return (
    <ConfigProvider direction={'ltr'}>
      <ThemeProvider theme={theme}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLoggedIn ? <Route path="/" component={(props:any) => <Auth from={from} {...props} waitingForAuthCheck={waitingForAuthCheck}/>} /> : <Route path="/admin" component={Admin} />}
          {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
            <Redirect to={param.includes("notification_token") ? from+param : (from !== '/' && from.includes('/admin')) ? from : "/admin"} />
          )}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
