import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Roleta = lazy(() => import('../../container/pages/RoletaDeLeads'));

const RoletaRoutes = () => {

  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Roleta} />
    </Switch>
  );
};

export default RoletaRoutes;
