import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Visitas = lazy(() => import('../../container/visitas-e-propostas/Visitas'));

const VisitasRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Visitas} />
    </Switch>
  );
};

export default VisitasRoutes;
