import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Relatorios = lazy(() => import('../../container/relatorios/Relatorios'));

const RelatoriosRoutes = () => {

  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={path} component={Relatorios} />
    </Switch>
  );
};

export default RelatoriosRoutes;
