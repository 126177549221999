import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Cliente = lazy(() => import('../../container/profile/cliente/Index'));

const EquipeRoutes = () => {
    const { path } = useRouteMatch();
    
    return (
        <Switch>
            <Route path={`${path}/:id_cliente`} component={Cliente} />
        </Switch>
    );
};

export default EquipeRoutes;
